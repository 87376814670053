import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../src/Components/Grid/GridItem";
import GridContainer from "../src/Components/Grid/GridContainer.js";
import Table from "../src/Components/Table/Table.js";
import Card from "../src/Components/Card/Card.js";
import CardHeader from "../src/Components/Card/CardHeader.js";
import CardBody from "../src/Components/Card/CardBody.js";
import { db } from "../src/firebase.js";
import * as ReactBootStrap from "react-bootstrap";
import Button from "../src/Components/CustomButtons/Button";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import { auth, logout } from "./firebase";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
let ps;
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles(styles);

export default function TableList() {
  const classes = useStyles();
  const [UsersReq, SetUsersReq] = useState(null);
  const [loading, setLoading] = useState(false);
  const [user, loadingAuth, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const history = useHistory();
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const fetchTable = async () => {
    try {
      await GetUsers();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchJobD = (Docslist, valkey) => {
    console.log("button works!");
    console.log(Docslist);
    console.log(valkey);
  };

  const GetUsers = () => {
    let Userslist = [];
    let Docslist = [];
    let contUsers = 0;
    let tableRow = [];
    let contDocs = 0;
    let Docdates = [];
    let DocsPass = 0;
    db.collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          Userslist.push(doc.id.toString());
          db.collection("users")
            .doc(doc.id)
            .collection("handymen")
            .where("Status", "==", "WFR")
            .get()
            .then((query) => {
              query.forEach((doc) => {
                Docslist.push(doc.id.toString());
                Docdates.push(String(doc.get("date")));
                contDocs++;
              });
              for (let y = 0; y < contDocs; y++) {
                Docdates[y] = Docdates[y].substring(18, 28);
                Docdates[y] = Docdates[y] * 1000;
                Docdates[y] = new Date(Docdates[y]).toLocaleDateString("en-US");
              }
              for (let x = DocsPass; x < contDocs; x++) {
                tableRow.push([
                  Userslist[contUsers],
                  Docslist[x],
                  Docdates[x],
                  <Button
                    color="primary"
                    key={Docslist[x]}
                    onClick={() => {
                      fetchJobD(Docslist, Docslist[x]);
                    }}
                  >
                    Details
                  </Button>,
                ]);
                DocsPass++;
              }
              contUsers++;
              SetUsersReq(
                <Table
                  tableHeaderColor="primary"
                  tableHead={["Email", "Document", "Date", "Description"]}
                  tableData={tableRow}
                />
              );
            });
        });
        console.log(Userslist);
        console.log(Docslist);
        console.log(tableRow);
        console.log(Docdates);
      });
    setLoading(true);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  
  useEffect(() => {
    if (!user) return history.replace("/");
    fetchTable();
  }, [user, loadingAuth]);
//   useEffect(() => {
//     fetchTable();
    
//   }, []);

React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div ref={mainPanel}>
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Simple Table</h4>
            <p className={classes.cardCategoryWhite}>
              Here is a subtitle for this table
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["Name", "Country", "City", "Salary", "Document"]}
              tableData={[
                ["Dakota-Rice", "Niger", "Oud-Turnhout", "$36,738", "yes"],
                ["Minerva-Hooper", "Curaçao", "Sinaai-Waas", "$23,789", "no"],
                ["Sage-Rodriguez", "Netherlands", "Baileux", "$56,142", "no"],
                [
                  "Philip-Chaney",
                  "Korea, South",
                  "Overland-Park",
                  "$38,735",
                  "no",
                ],
                [
                  "Doris-Greene",
                  "Malawi",
                  "Feldkirchen-in-Kärnten",
                  "$63,542",
                  "no",
                ],
                ["Mason-Porter", "Chile", "Gloucester", "$78,615", "no"],
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card plain>
          <CardHeader plain color="primary">
            <h4 className={classes.cardTitleWhite}>
              Table on Plain Background
            </h4>
            <p className={classes.cardCategoryWhite}>
              Here is a subtitle for this table
            </p>
          </CardHeader>
          <CardBody>
            {loading ? UsersReq : <ReactBootStrap.Spinner animation="border" />}
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Simple Table</h4>
            <p className={classes.cardCategoryWhite}>
              Here is a subtitle for this table
            </p>
          </CardHeader>
          <CardBody>
            <Table
              tableHeaderColor="primary"
              tableHead={["Name", "Country", "City", "Salary", "Document"]}
              tableData={[
                ["Dakota-Rice", "Niger", "Oud-Turnhout", "$36,738", "yes"],
                ["Minerva-Hooper", "Curaçao", "Sinaai-Waas", "$23,789", "no"],
                ["Sage-Rodriguez", "Netherlands", "Baileux", "$56,142", "no"],
                [
                  "Philip-Chaney",
                  "Korea, South",
                  "Overland-Park",
                  "$38,735",
                  "no",
                ],
                [
                  "Doris-Greene",
                  "Malawi",
                  "Feldkirchen-in-Kärnten",
                  "$63,542",
                  "no",
                ],
                ["Mason-Porter", "Chile", "Gloucester", "$78,615", "no"],
              ]}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    </div>
  );
}