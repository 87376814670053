import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import { auth, db, logout } from "./firebase";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import { formatRelative } from "date-fns";
import "@reach/combobox/styles.css";
import ExploreIcon from "@material-ui/icons/Explore";
import { Search } from "@material-ui/icons";
import { position } from "dom-helpers";

const libraries = ["places"];
const mapContainerStyle = {
  width: "100vw",
  height: "100vh",
};
const center = {
  lat: 32.641178,
  lng: -115.475578,
};

export default function Maps() {
  const [user, loading, error] = useAuthState(auth);
  const history = useHistory();
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBm0niUYkeeaxYfyFctoO_bXhu4LLrpYuM",
    libraries,
  });

  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState(null);
  const [LocA, setLocA] = useState(null);
  const [LocR, setLocR] = React.useState([]);

  function Locate({ panTo }) {
    return (
      <button
        className="locate"
        onClick={() => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              panTo({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            () => null
          );
        }}
      >
        <ExploreIcon alt="Compass - locate me" />
      </button>
    );
  }
  const fetchLocs = async () => {
    try {
      await LocateAdmin();
      await LocateReqs();
    } catch (err) {
      console.log("error");
    }
  };
  const LocateAdmin = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const Aloc = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setLocA(<Marker key="AdminLocation" position={Aloc} />);
      },
      () => null
    );
  };

  const LocateReqs = () => {
    let Userslist = [];
    let Docslist = [];
    let contUsers = 0;
    let contDocs = 0;
    let DocLocs = [];
    let DocsPass = 0;
    let Locs = [];
    db.collection("users")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          Userslist.push(doc.id.toString());
          db.collection("users")
            .doc(doc.id)
            .collection("handymen")
            .where("Status", "==", "WFR")
            .get()
            .then((query) => {
              query.forEach((doc) => {
                Docslist.push(doc.id.toString());
                DocLocs.push(String(doc.get("coor")));
                contDocs++;
              });
              //Ajuste a formato de Marker JSX
              for (let x = DocsPass; x < contDocs; x++) {
                Locs.push({
                  lat: parseFloat(DocLocs[x].split(",")[0].trim()),
                  lng: parseFloat(DocLocs[x].split(",")[1].trim()),
                  id: Docslist[x],
                });
                DocsPass++;
              }
              setLocR(Locs);
              contUsers++;
            });
        });
      });
  };

  function Search({ panTo }) {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        location: { lat: () => 32.641178, lng: () => -115.475578 },
        radius: 200 * 1000,
      },
    });

    return (
      <div className="search">
        <Combobox
          onSelect={async (address) => {
            try {
              const results = await getGeocode({ address });
              const { lat, lng } = await getLatLng(results[0]);
              panTo({ lat, lng });
            } catch (error) {
              console.log("error");
            }

            console.log(address);
          }}
        >
          <ComboboxInput
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            disabled={!ready}
            placeholder="Enter an address"
          />
          <ComboboxPopover>
            <ComboboxList>
              {status === "OK" &&
                data.map(({ id, description }) => (
                  <ComboboxOption key={id} value={description} />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </div>
    );
  }

  const onMapClick = React.useCallback((event) => {
    setMarkers((current) => [
      ...current,
      {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
        time: new Date(),
      },
    ]);
  }, []);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(14);
  }, []);

  useEffect(() => {
    if (loading) return;
    if (!user) return history.replace("/");
    fetchLocs();
  }, [user, loading]);
  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";
  return (
    <div>
      <Search panTo={panTo} />
      <Locate panTo={panTo} />

      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={8}
        center={center}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {markers.map((marker) => (
          <Marker
            key={marker.time.toISOString()}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() => {
              setSelected(marker);
            }}
          />
        ))}
        {LocA}
        {LocR.map((Locs) => (
          <Marker
            key={Locs.id}
            position={{ lat: Locs.lat, lng: Locs.lng }}
            //PARA PONER VENTANA DE CUAL REQUEST ES
            // onClick={() => {
            //   setSelected(marker);
            // }}
          />
        ))}
        {selected ? (
          <InfoWindow
            position={{ lat: selected.lat, lng: selected.lng }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div>
              <h2>ZA WARUDO! bish</h2>
              <p>Time Stopped at {formatRelative(selected.time, new Date())}</p>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  );
}
