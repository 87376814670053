import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory } from "react-router";
import "./Dashboard.css";
import { auth, db, logout } from "./firebase";
export default function Chat() {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const history = useHistory();
  useEffect(() => {
    if (loading) return;
    if (!user) return history.replace("/");
  }, [user, loading]);
  return (
    <div className="dashboard">
      <div className="dashboard__container">
        Aqui estaria el chat
        <button className="dashboard__btn" onClick={logout}>
          Logout
        </button>
      </div>
    </div>
  );
}
