import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAeLAvY5OasoyxJA-_jM9ZMyWYSrUw1Yk8",
  authDomain: "handyman-pro-88977.firebaseapp.com",
  projectId: "handyman-pro-88977",
  storageBucket: "handyman-pro-88977.appspot.com",
  messagingSenderId: "586258986486",
  appId: "1:586258986486:web:f3a968b49e95289c3a83d7",
  measurementId: "G-E3YWYGWSRT"
};//Configuracion de Firebase, preguntarle a german sobre cuenta

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const db = app.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    const query = await db
      .collection("admins")
      .where("uid", "==", user.uid)
      .get();
    if (query.docs.length === 0) {
      await db.collection("admins").add({
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signInWithEmailAndPassword = async (email, password) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const registerWithEmailAndPassword = async (name, email, password, phone, Aloc) => {
  try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    const user = res.user;
    await db.collection("admins").doc(email).set({
      uid: user.uid,
      name,
      authProvider: "local",
      email,
      phone,
      coor: Aloc,
      
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  auth.signOut();
};

export {
  auth,
  db,
  signInWithGoogle,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  logout,
};
