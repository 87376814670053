import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import Reset from "./Reset";
import Dashboard from "./Dashboard";
import Admin from "./Admin";
import Sidebar from "../src/Components/Sidebar/Sidebar.js";
import Navbar from "./Components/Navbars/Navbar.js";
import routes from "../src/routes.js";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import bgImage from "../src/Assets/img/sidebar-2.jpg";
import logo from "../src/Assets/img/reactlogo.png";
import Chat from "./Chat";
import styles from "../src/Assets/jss/material-dashboard-react/layouts/adminStyle.js";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Components/Footer/Footer.js";
import FixedPlugin from "./Components/FixedPlugin/FixedPlugin.js";
import TableList from "./Requests";
import Maps from "./Maps";
let ps;
const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/login" />
  </Switch>
);
const useStyles = makeStyles(styles);
function App({ ...rest }) {
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleImageClick = (image) => {
    setImage(image);
  };
  const handleColorClick = (color) => {
    setColor(color);
  };
  const handleFixedClick = () => {
    if (fixedClasses === "dropdown") {
      setFixedClasses("dropdown show");
    } else {
      setFixedClasses("dropdown");
    }
  };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className="app">
      <Router>
      <div className={classes.mainPanel} ref={mainPanel}>
      <Sidebar
        routes={routes}
        logoText={"Handyman"}
        logo={logo}
        image={bgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"blue"}
        {...rest}
      />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/admin" component={Admin}/>
          <Route exact path="/reset" component={Reset} />
          <Route exact path="/admin/dashboard" component={Dashboard}/>
          <Route exact path="/admin/chat" component={Chat}/>
          <Route exact path="/admin/login" component={Login} />
          <Route exact path="/admin/requests" component={TableList} />
          <Route exact path="/admin/maps" component={Maps} />
        </Switch>
        </div>
      </Router>
    </div>
  );
}
export default App;


//Codigo de https://blog.logrocket.com/user-authentication-firebase-react-apps/
