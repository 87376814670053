import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useHistory } from "react-router-dom";
import {
  auth,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "./firebase";
import "./Register.css";
function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [admin, loading, error] = useAuthState(auth);
  const [coor, setCoors] = useState("");
  const history = useHistory();
  const register = () => {
    if (!name) alert("Please enter name")
    //let Aloc = "No coordenadas";
    fetchLoc(name, email,password,phone)
    //registerWithEmailAndPassword(name, email, password, phone, Aloc);
  };
  const LocateAdmin = (name, email, password, phone) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        let Aloc = String(position.coords.latitude)+","+String(position.coords.longitude)
        setCoors(Aloc) 
          registerWithEmailAndPassword(name, email, password, phone, Aloc);
      },
      () => null
    );
  };

  const fetchLoc = async(name, email,password,phone) => {
    try{
      await LocateAdmin(name, email, password, phone);
    }catch(err){
      console.log("Error al obtener ubicacion")
    }
  }
  useEffect(() => {
    if (loading) return;
    if (admin) history.replace("/admin/dashboard");
  }, [admin, loading]);
  return (
    <div className="register">
      <div className="register__container">
        <input
          type="text"
          className="register__textBox"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Full Name"
        />
        <input
          type="text"
          className="register__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="register__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
         <input
          type="tel"
          className="register__textBox"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Phone Number"
          maxLength = "10"
        />
        <button className="register__btn" onClick={register}>
          Register
        </button>
        <button
          className="register__btn register__google"
          onClick={signInWithGoogle}
        >
          Register with Google
        </button>
        <div>
          Already have an account? <Link to="/">Login</Link> now.
        </div>
      </div>
    </div>
  );
}
export default Register;